import React from "react"

import { withAccountLogout } from "./withAccountLogout"
import { Button } from "./AccountLogoutStyles"

export const AccountLogout = withAccountLogout(({ handleLogout, locales }) => (
  <Button type={`button`} onClick={handleLogout}>
    {locales?.signOut}
  </Button>
))
