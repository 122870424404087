import tw from "twin.macro"

import SvgNext from "static/icons/theme/next.svg"
import SvgPrev from "static/icons/theme/previous.svg"
import { TextButton } from "../../Styled/Button"

export const IconNext = tw(SvgNext)`block w-2 h-2 ml-0-2`
export const IconPrev = tw(SvgPrev)`block w-2 h-2 ml-0-2`
export const StyledTextButton = tw(TextButton)`absolute inset-0 px-0-4 flex items-center justify-start whitespace-no-wrap hover:no-underline`
export const Placeholder = tw.div`w-2/3 py-0-9 my-0-1 bg-grey-lightest animate-placeholder`
export const Pagination = tw.div`flex items-center justify-center mt-2 gap-1`
