import React, { useState } from "react"

import { useCustomerOrders } from "../../../hooks/useCustomer"
import { useLanguage } from "../../../hooks/useLanguage"
import { Account } from "../../Account/Account"

export const withCustomerOrders = Component => ({ name = `CustomerOrders`, ...props }) => {
  const { orders, loading, error, handleNextPage, handlePreviousPage, hasNextPage, hasPreviousPage, page, totalPages } = useCustomerOrders(15)
  const locales = useLanguage(`account`)

  const empty = (!loading && orders?.length === 0) || error

  Component.displayName = name
  return (
    <Account {...props} description={empty ? locales.orderHistoryEmpty : locales.orderHistoryDescription} title={locales.orderHistoryTitle}>
      {!empty && (
        <Component
          error={error}
          loading={loading}
          orders={orders}
          handleNextPage={handleNextPage}
          handlePreviousPage={handlePreviousPage}
          hasNextPage={hasNextPage}
          hasPreviousPage={hasPreviousPage}
          page={page}
          totalPages={totalPages}
        />
      )}
    </Account>
  )
}
