import React from "react"
import { navigate } from "gatsby"

import { useApp } from "../hooks/useApp"
import { useCore } from "../hooks/useCore"
import { useCustomerAccessToken, useCustomerContext } from "../hooks/useCustomer"
import { useCheckoutContext } from "../hooks/useCheckout"
import { useRoutes } from "../hooks/useRoutes"

export const withAuthentication = Component => ({ location, ...props }) => {
  const {
    helpers: { isBrowser, storage },
  } = useCore()

  const {
    config: {
      settings: { keys, params, routes },
    },
  } = useApp()

  const { customer } = useCustomerContext()
  // const { gotoCheckout } = useCheckoutContext()
  const customerTokens = storage.get(keys?.customer)

  const route =
    location?.pathname === routes?.ACCOUNT_SAVED
      ? routes?.SAVED
      : `${routes?.ACCOUNT_LOGIN}${location.pathname !== routes.ACCOUNT ? `?${params?.continue}=${location.pathname}` : ``}`

  if (!customerTokens?.accessToken || new Date(customerTokens?.expiresAt) <= new Date()) {
    storage.remove(keys?.customer)
    console.log('%cwithAuthentication.tsx line:32 route', 'color: #007acc;', route);
    if (isBrowser) navigate(route, { replace: true })

    return null
  }

  // const { getCustomer } = useCustomerAccessToken()
  // if (!customer?.id) getCustomer()

  return <Component location={location} {...props} />
}

export const withoutAuthentication = Component => ({ location, ...props }) => {
  const {
    helpers: { isBrowser, storage },
  } = useCore()

  const {
    config: {
      settings: { keys, routes, params },
    },
  } = useApp()

  const { getUrlParameter } = useRoutes()

  const customerTokens = storage.get(keys?.customer)
  const invoiceUrl = getUrlParameter(params?.checkout)

  if (customerTokens?.accessToken && new Date(customerTokens?.expiresAt) >= new Date()) {
    if (isBrowser) {
      const path = getUrlParameter(params.continue)
      const checkoutUrl = getUrlParameter(params.checkout)
      const isCheckout = !!checkoutUrl
      const route = isCheckout ? routes.CHECKOUT : path ? path : routes?.DASHBOARD

      navigate(route, { replace: true })
    }
  }

  // const route =
  //   location?.pathname === routes?.CART_LOGIN
  //     ? routes?.CART
  //     : location?.pathname === routes?.SAVED
  //     ? routes?.ACCOUNT_SAVED
  //     : `${routes?.ACCOUNT}${invoiceUrl ? `?${params?.checkout}=${invoiceUrl}` : ""}`

  // if (customerTokens?.accessToken && new Date(customerTokens?.expiresAt) >= new Date()) {
  //   if (isBrowser) navigate(route, { replace: true })

  //   return null
  // }

  return <Component {...props} />
}
