import React from "react"

import { withCustomerOrders } from "./withCustomerOrders"
import { Table } from "../../Styled/Table"
import { TextButton } from "../../Styled/Button"
import { Pagination, IconNext, IconPrev } from "./CustomerOrdersStyles"
import { CustomerOrdersHeader } from "./Header/CustomerOrdersHeader"
import { CustomerOrdersList } from "./List/CustomerOrdersList"
import { CustomerOrdersPlaceholder } from "./Placeholder/CustomerOrdersPlaceholder"

interface Props {
  loading: any
  orders: Array<any>
  handleNextPage: () => void
  handlePreviousPage: () => void
  hasNextPage: boolean
  hasPreviousPage: boolean
  page: number
  totalPages: number
}

export const CustomerOrders = withCustomerOrders(
  ({ loading, orders, handleNextPage, handlePreviousPage, hasNextPage, hasPreviousPage, page, totalPages }: Props) => (
    <>
      <Table>
        <CustomerOrdersHeader />
        {loading ? <CustomerOrdersPlaceholder /> : <CustomerOrdersList orders={orders} />}
      </Table>
      {totalPages > 1 && (
        <Pagination>
          <TextButton disabled={!hasPreviousPage} onClick={handlePreviousPage}>
            <IconPrev />
          </TextButton>
          <span>
            {page} of {totalPages}
          </span>
          <TextButton disabled={!hasNextPage} onClick={handleNextPage}>
            <IconNext />
          </TextButton>
        </Pagination>
      )}
    </>
  )
)
