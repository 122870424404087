import React from "react"

import { withAccount } from "./withAccount"
import { AccountNavigation } from "./Navigation/AccountNavigation"
import { StyledContainer, Heading, Text, Aside, Content, IconPrev } from "./AccountStyles"

interface Props {
  backNavText: string
  children: any
  description: string
  full: boolean
  left: any
  location: any
  onBackNavAction: Function
  showBackNav: string
  title: string
}

export const Account = withAccount(({ children, description, full, location, title, left, backNavText, showBackNav, onBackNavAction }: Props) => (
  <>
    <StyledContainer as={`section`} header>
      <Heading as={`h1`} left={left}>
        {title}
      </Heading>

      {showBackNav ? (
        <Text left={left} isButton={showBackNav} onClick={onBackNavAction}>
          <IconPrev /> <span>{backNavText}</span>
        </Text>
      ) : (
        <Text left={left}>{description}</Text>
      )}
    </StyledContainer>

    <StyledContainer as={`section`}>
      <Aside>
        <AccountNavigation description={description} location={location} />
      </Aside>

      <Content full={full}>{children}</Content>
    </StyledContainer>
  </>
))
