import React from "react"

import { useApp } from "../../../hooks/useApp"
import { useCustomerLogout } from "../../../hooks/useCustomer"
import { useLanguage } from "../../../hooks/useLanguage"
import { useLocation } from "../../../hooks/useLocation"

export const withAccountNavigation = Component => ({ name = `AccountNavigation`, ...props }) => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()
  const { logoutCustomer } = useCustomerLogout()
  const { title, detailsTitle, preferencesTitle, orderHistoryTitle, addressesTitle, signOut: signOutLabel } = useLanguage(`account`)
  const { title: wishlistTitle } = useLanguage(`wishlist`)
  const { storeConfig } = useLocation()

  const NAVIGATION = [
    {
      url: routes.ACCOUNT,
      label: detailsTitle,
    },
    {
      url: routes.ACCOUNT_SETTINGS,
      label: preferencesTitle,
    },
    {
      url: routes.ACCOUNT_ORDERS,
      label: orderHistoryTitle,
    },
    {
      url: routes.ACCOUNT_CUSTOM_PAYMENT,
      label: "onedayPAY",
      hidden: storeConfig?.wholesale,
    },
    {
      url: routes.ACCOUNT_ADDRESSES,
      label: addressesTitle,
    },
    {
      url: routes.ACCOUNT_SAVED,
      label: wishlistTitle,
    },
    {
      hidden: routes.ACCOUNT_SAVED,
      label: signOutLabel,
    },
  ]

  const active =
    NAVIGATION.find(link => location?.pathname === link?.url || (link?.url?.includes("onedaypay") && location?.pathname?.includes("onedaypay"))) ||
    NAVIGATION[0]

  Component.displayName = name
  return <Component {...props} active={active} handleLogout={() => logoutCustomer()} navigation={NAVIGATION} title={title} />
}
