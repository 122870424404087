import styled from "@emotion/styled"
import tw from "twin.macro"
import { Link } from "gatsby"

import Prev from "static/icons/theme/previous.svg"
import { Container } from "../Styled/Container"
import { P, H5 } from "../Styled/Text"

export const StyledContainer = styled(Container)`
  ${props =>
    props.header ? tw`hidden md:block max-w-lg text-center justify-center pt-8 md:pt-12` : tw`max-w-lg flex flex-wrap py-8 md:py-12 md:pt-0`}
  ${props => (props.header ? `text-align: -moz-center;` : ``)}
  ${props => (props.footer ? tw`pb-8 md:pb-12` : ``)}
`
export const StyledContainerMedium = tw(StyledContainer)`block max-w-md`

export const Aside = tw.nav`w-full md:w-1/4`
export const Content = styled.div`
  ${tw`w-full`}
  ${props => (props.full ? tw`md:w-3/4` : tw`md:w-1/2`)}
`

export const Controls = tw.div`flex items-center justify-between -mx-0-6`
export const Control = styled.div`
  ${tw`flex-1 px-0-6`}
  ${props => (props.hiddenSm ? tw`hidden md:block` : ``)}
`

export const Heading = styled(H5)`
  ${tw`w-1/2 mx-auto text-center mb-0-8 text-center`}
  ${({ hiddenSm }) => hiddenSm && tw`hidden md:block`}
  ${({ left }) => left && tw`text-left normal-case`}
`
export const Subheading = tw(P)`mb-1-2`
export const Text = styled(P)`
  ${tw`mb-4 flex w-1/2 text-xs mx-auto md:text-sm`}
  ${props => (props.left ? tw`text-left justify-start` : tw`text-center justify-center`)}
  ${props => (props.isButton ? tw`cursor-pointer` : ``)}
`
export const Label = tw(P)`text-grey-darker mb-0-2`
export const FormSection = tw(P)`mt-4 text-left`

export const DesktopNavigation = tw.ul`hidden md:block`
export const MobileNavigation = tw.div`block md:hidden mb-1-2`
export const AccountNavigationDropdown = tw.div`mt-2-4 mb-1-2`
export const AccountNavigationDescription = tw(Text)`max-w-26 mx-auto`

export const NavigationLink = styled(Link)`
  ${tw`text-xs tracking-relaxed leading-loosest`}
  ${props => (props.active ? tw`underline` : tw`hover:underline`)}
`
export const IconPrev = tw(Prev)`block w-2 h-2 mr-0-6`
