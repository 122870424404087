import React from "react"
import { navigate } from "gatsby"

import { withAccountNavigation } from "./withAccountNavigation"
import { Dropdown } from "../../Styled/Dropdown/Dropdown"
import { AccountLogout } from "../Logout/AccountLogout"
import {
  NavigationLink,
  Heading,
  Subheading,
  DesktopNavigation,
  MobileNavigation,
  AccountNavigationDropdown,
  AccountNavigationDescription,
} from "../AccountStyles"

interface Props {
  active: any
  description: string
  handleLogout: Function
  navigation: Array<any>
  title: string
  location: any
}

export const AccountNavigation = withAccountNavigation(({ active, description, handleLogout, navigation, title }: Props) => (
  <>
    <DesktopNavigation>
      <Subheading>{title}</Subheading>

      <ul>
        {navigation
          .filter((link: any) => !link.hidden)
          .map((link: any) => (
            <li key={link.label}>
              <NavigationLink active={active.label === link.label && `true`} to={link.url}>
                {link.label}
              </NavigationLink>
            </li>
          ))}
      </ul>

      <AccountLogout />
    </DesktopNavigation>

    <MobileNavigation>
      <Heading>{title}</Heading>

      <AccountNavigationDropdown>
        <Dropdown
          onChange={(value: string) => (navigation.find(({ url }) => url === value) ? navigate(value) : handleLogout())}
          options={navigation.map((link: any) => ({ label: link.label, value: link.url || null }))}
          value={active.url}
        />
      </AccountNavigationDropdown>

      <AccountNavigationDescription>{description}</AccountNavigationDescription>
    </MobileNavigation>
  </>
))
