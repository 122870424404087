import React from "react"

import { ORDER_HISTORY_COLUMNS } from "../CustomerOrdersConsts"
import { Row, Column } from "../../../Styled/Table"
import { Placeholder } from "../CustomerOrdersStyles"

export const withCustomerOrdersPlaceholder = Component => ({ name = `CustomerOrdersPlaceholder` }) => {
  const orderPlaceholders = []

  for (var i = 0; i < 15; i++) {
    orderPlaceholders.push(
      <Row key={i}>
        {ORDER_HISTORY_COLUMNS.map((column, index) => (
          <Column key={index} columns={ORDER_HISTORY_COLUMNS.length} first>
            <Placeholder />
          </Column>
        ))}
      </Row>
    )
  }

  Component.displayName = name
  return <Component>{orderPlaceholders}</Component>
}
