import React from "react"

import { useCustomerLogout } from "../../../hooks/useCustomer"
import { useLanguage } from "../../../hooks/useLanguage"

export const withAccountLogout = Component => ({ name = `AccountLogout` }) => {
  const { logoutCustomer } = useCustomerLogout()
  const locales = useLanguage(`account`)

  Component.displayName = name
  return <Component handleLogout={() => logoutCustomer()} locales={locales} />
}
