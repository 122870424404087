import React, { useState } from "react"

import { useShopify } from "../../../../hooks/useShopify"
import { ORDER_HISTORY_COLUMNS } from "../CustomerOrdersConsts"

export const withCustomerOrdersList = Component => ({ name = `CustomerOrdersList`, orders }) => {
  const [highlighted, setHighlighted] = useState({ x: null, y: null })
  const { formatMoney } = useShopify()

  Component.displayName = name
  return (
    <Component columns={ORDER_HISTORY_COLUMNS} formatMoney={formatMoney} highlighted={highlighted} orders={orders} setHighlighted={setHighlighted} />
  )
}
